import {throttle} from 'lodash';

export default () => ({
    visibleNav: false,
    hiddenNav: true,
    logo: null,
    nav: null,
    navContainer: null,
    search: null,
    searchBackdrop: null,
    visibleSearch: false,
    hiddenSearch: true,
    enterTimer: [],
    leaveTimer: [],

    init() {
        this.logo = this.$root.querySelector('#logo');
        this.nav = this.$root.querySelector('.nav-container nav');
        this.navContainer = this.$root.querySelector('.nav-container');
        this.search = this.$root.querySelector('.nav-search');
        this.searchBackdrop = document.createElement('div');
        this.scrollHandler();
        this.searchBackdrop.classList.add(
            'search-backdrop',
            'hidden',
            'z-40',
            'w-full',
            'fixed',
            'inset-0',
            'bg-gray-900',
            'opacity-25'
        );
        document.body.appendChild(this.searchBackdrop);
        window.addEventListener('scroll', throttle(this.scrollHandler.bind(this), 100), {passive: true});

        if (!window.isTouchDevice() && window.isMinScreenSize('md')) {
            this.initHover();
            this.initContinentHover();
        }
    },

    initHover() {
        this.$root.querySelectorAll('nav > ul > li > a[\\@click\\.prevent="openSub"]').forEach((elem, index) => {
            const listItem = elem.closest('li');
            listItem.addEventListener('mouseenter', event => {
                const sub = event.target.querySelector('.sub');
                if (sub) {
                    clearTimeout(this.leaveTimer[index]);
                    this.enterTimer[index] = setTimeout(() => {
                        this.$root.querySelectorAll('.sub').forEach(subElem => {
                            if (!subElem.isSameNode(sub)) {
                                subElem.classList.add('hidden');
                            }
                        });
                        sub.classList.remove('hidden');
                    }, 300);
                }
            });
            listItem.addEventListener('mouseleave', event => {
                const sub = event.target.querySelector('.sub');
                if (sub) {
                    clearTimeout(this.enterTimer[index]);
                    this.leaveTimer[index] = setTimeout(() => sub.classList.add('hidden'), 300);
                }
            });
        });
    },

    scrollHandler() {
        if (window.scrollY > 60) {
            this.navContainer.classList.remove('md:h-20', 'lg:h-27');
            this.navContainer.classList.add('md:h-14', 'lg:h-20');
            this.logo.classList.remove('h-12', 'lg:h-15');
            this.logo.classList.add('h-9', 'lg:h-12');
            this.nav.classList.remove('md:top-20');
            this.nav.classList.add('md:top-14');
        } else {
            this.navContainer.classList.remove('md:h-14', 'lg:h-20');
            this.navContainer.classList.add('md:h-20', 'lg:h-27');
            this.logo.classList.remove('h-9', 'lg:h-12');
            this.logo.classList.add('h-12', 'lg:h-15');
            this.nav.classList.remove('md:top-14');
            this.nav.classList.add('md:top-20');
        }
    },

    toggleNav() {
        this.visibleNav = !this.visibleNav;
        this.hiddenNav = !this.hiddenNav;

        document.body.classList.toggle('overflow-hidden');

        if (this.hiddenNav) {
            this.nav
                .querySelectorAll(
                    '.service-menu, .group > a, .group > .sub a[\\@click\\.prevent="closeSub"], .group > .sub .relative > div, .group > .sub .relative > ul > li > a'
                )
                .forEach(element => element.classList.remove('hidden'));
            this.nav.querySelectorAll('li > a[\\@click\\.prevent="openSub"]').forEach(element => {
                element.nextElementSibling.classList.add('hidden');
            });
        }
    },

    hideNav() {
        this.visibleNav = false;
        this.hiddenNav = true;
    },

    toggleSearch(event) {
        event.target?.blur();
        if (this.search) {
            this.visibleSearch = !this.visibleSearch;
            this.hiddenSearch = !this.hiddenSearch;
            this.search.classList.toggle('hidden');
            document.body.classList.toggle('overflow-hidden');
            document.body.querySelector('.search-backdrop').classList.toggle('hidden');
            if (!this.search.classList.contains('hidden')) {
                this.search.querySelector('input').focus({preventScroll: true, focusVisible: true});
            }
        }
    },

    clearSearch(event) {
        if (this.search) {
            const oldValue = this.search.querySelector('input').value;
            this.search.querySelector('input').value = '';
            if (oldValue.length) {
                this.search.querySelector('input').dispatchEvent(new Event('input'));
            }
            this.search.querySelector('input').focus({preventScroll: true, focusVisible: true});
        }
    },

    openSub(event) {
        event.preventDefault();

        const link = event.target.closest('a');

        if (window.isTouchDevice() || !window.isMinScreenSize('md')) {
            if (window.isMinScreenSize('md')) {
                this.mdOpenSub(link);

                return;
            }

            link.closest('ul')
                .querySelectorAll(':scope > li > a[\\@click\\.prevent="openSub"]')
                .forEach(element => {
                    if (!element.nextElementSibling.isEqualNode(link.nextElementSibling)) {
                        element.nextElementSibling.classList.add('hidden');
                    }
                });
            link.nextElementSibling.closest('.sub')?.classList.toggle('hidden');
            if (link.closest('li').classList.contains('group/continent')) {
                link.closest('.sub')
                    .querySelectorAll(
                        ':scope > a[\\@click\\.prevent="closeSub"], :scope > .relative > div, :scope > .relative > ul > li > a'
                    )
                    .forEach(element => element.classList.toggle('hidden'));
            } else {
                this.nav
                    .querySelectorAll('.group > a, .service-menu')
                    .forEach(element => element.classList.toggle('hidden'));
            }
        } else {
            window.location.href = link.getAttribute('href');
        }
    },

    mdOpenSub(link) {
        if (link.getAttribute('href') === '/reise-suchen') {
            window.location.href = link.getAttribute('href');

            return;
        }

        link.closest('ul')
            .querySelectorAll(':scope > li > a[\\@click\\.prevent="openSub"]')
            .forEach(element => {
                if (
                    !element.nextElementSibling.classList.contains('hidden') &&
                    !element.nextElementSibling.isEqualNode(link.nextElementSibling)
                ) {
                    element.nextElementSibling.classList.add('hidden');
                }
            });
        link.nextElementSibling.classList.toggle('hidden');

        if (link.closest('li').classList.contains('group/continent')
            && !link.closest('li').classList.contains('.continent-0')) {
            this.$root.querySelector('.continent-0 div').style.display = 'none';
        }
    },

    closeSub(event) {
        event.preventDefault();
        event.target.closest('.sub')?.previousElementSibling?.dispatchEvent(new Event('click', {bubbles: true}));
    },

    initContinentHover() {
        this.$root.querySelectorAll('.group\\/continent').forEach(element => {
            element.addEventListener(
                'mouseenter',
                () => (this.$root.querySelector('.continent-0 div').style.display = 'none')
            );
            element.addEventListener(
                'mouseleave',
                () => (this.$root.querySelector('.continent-0 div').style.display = 'block')
            );
        });
    }
});
